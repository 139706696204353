import imgmyworks from "../img/imgmyworks.jpg";
export const projects = [
    {
      id: 1,
      title: "DỰ ÁN 1",
      description: "Website Bán, Oder Nước",
      image: imgmyworks,
      contentFile: "Project1Content",
    },
    {
      id: 2,
      title: "DỰ ÁN 2",
      description: "Website Bán Nông Sản",
      image: imgmyworks,
      contentFile: "Project2Content",
    },
   
  ];
  